<template>
    <TheLayout>
        <AppLayout variant="vertical">
            <AppHeader>
                <AppTitle v-translate>
                    Weekplanning
                </AppTitle>
            </AppHeader>

            <div v-if="allScheduleTemplates.length > 0">
                <AppOverviewList>
                    <thead class="overview-list-head">
                        <tr class="overview-list__row">
                            <th v-translate class="overview-list__column">
                                Naam
                            </th>
                            <th v-translate class="overview-list__column">
                                Groep
                            </th>
                            <th v-translate class="overview-list__column">
                                Leerkracht
                            </th>
                            <th v-translate class="overview-list__column">
                                Weekdag
                            </th>
                        </tr>
                    </thead>
                    <tbody class="overview-list-body">
                        <tr
                            v-for="schedule in allScheduleTemplates"
                            :key="schedule.id"
                            class="overview-list__row"
                        >
                            <td class="overview-list__column">
                                {{ schedule.group.name }}
                            </td>
                            <td
                                v-translate="{
                                    grade: schedule.grade
                                }"
                                class="overview-list__column"
                            >
                                Groep %{grade}
                            </td>
                            <td class="overview-list__column">
                                {{ schedule.group.teachers }}
                            </td>
                            <td class="overview-list__column">
                                <AppButtonGroup class="u-padding-tiny">
                                    <AppButton
                                        v-for="(data, day) in schedule.weekdays"
                                        :key="data.id"
                                        as="router-link"
                                        variant="light small status"
                                        :to="{
                                            name: 'schedule_weekly_detail',
                                            params: { id: data.id }
                                        }"
                                    >
                                        <AppIcon
                                            v-if="data.hasSchedule"
                                            name="check"
                                            variant="extra-tiny"
                                            class="link__icon"
                                        />

                                        {{ weekdays[day] }}
                                    </AppButton>
                                </AppButtonGroup>
                            </td>
                        </tr>
                    </tbody>
                </AppOverviewList>
            </div>
            <AppLayoutInner
                v-else-if="!loading"
                variant="overflow-page background centered"
            >
                <AppOverviewPlaceholder>
                    <AppTitle v-translate class="overview-placeholder__title">
                        Geen weekplanning gevonden
                    </AppTitle>
                    <p v-translate>
                        U bent niet toegewezen aan een groep. Vraag een
                        beheerder om toegang te krijgen tot uw groep.
                    </p>
                </AppOverviewPlaceholder>
            </AppLayoutInner>
        </AppLayout>
    </TheLayout>
</template>

<script>
import AppButton from "@/components/AppButton.vue";
import AppButtonGroup from "@/components/AppButtonGroup.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppIcon from "@/components/AppIcon";
import AppLayout from "@/components/AppLayout.vue";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppOverviewList from "@/components/AppOverviewList.vue";
import AppOverviewPlaceholder from "@/components/AppOverviewPlaceholder";
import AppTitle from "@/components/AppTitle.vue";
import TheLayout from "@/components/TheLayoutPage";
import { cloneDeep } from "lodash";
import { consoleError } from "@/console";
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
    name: "Weekly",
    components: {
        AppLayoutInner,
        AppButton,
        AppButtonGroup,
        AppOverviewList,
        AppTitle,
        AppHeader,
        AppLayout,
        AppIcon,
        AppOverviewPlaceholder,
        TheLayout
    },
    data() {
        return {
            allScheduleTemplates: [],
            loading: 0
        };
    },
    computed: {
        weekdays() {
            return this.$moment
                .weekdaysMin(true)
                .reduce((acc, value, index) => {
                    acc[`A_${index}`] = value;
                    return acc;
                }, {});
        }
    },
    apollo: {
        allScheduleTemplates: {
            query: gql`
                query allScheduleTemplatesWeekly {
                    allScheduleTemplates {
                        edges {
                            node {
                                id
                                weekday
                                grade {
                                    id
                                    grade
                                }
                                group {
                                    id
                                    name
                                    teachers {
                                        edges {
                                            node {
                                                id
                                                fullName
                                            }
                                        }
                                    }
                                    grades
                                }
                                scheduleTemplateRows(first: 0) {
                                    pageInfo {
                                        hasNextPage
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            update(data) {
                if (data.allScheduleTemplates) {
                    return data.allScheduleTemplates.edges
                        .map(x => {
                            const node = cloneDeep(x.node);
                            node.group.teachers = node.group.teachers.edges
                                .map(x => x.node.fullName)
                                .join(", ");
                            node.grade =
                                node.grade != null
                                    ? node.grade.grade
                                    : node.group.grades.join("/");
                            return node;
                        })
                        .reduce((acc, obj, index, schedules) => {
                            if (
                                index ===
                                schedules.findIndex(
                                    x =>
                                        x.group.id === obj.group.id &&
                                        x.grade === obj.grade
                                )
                            ) {
                                acc.push(obj);
                            }
                            const accIndex = acc.findIndex(
                                x =>
                                    x.group.id === obj.group.id &&
                                    x.grade === obj.grade
                            );
                            if (
                                !Object.prototype.hasOwnProperty.call(
                                    acc[accIndex],
                                    "weekdays"
                                )
                            ) {
                                acc[accIndex].weekdays = {};
                            }
                            acc[accIndex].weekdays[obj.weekday] = {
                                id: obj.id,
                                hasSchedule:
                                    obj.scheduleTemplateRows.pageInfo
                                        .hasNextPage
                            };
                            return acc;
                        }, []);
                } else {
                    return this.allScheduleTemplates;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van weekplanningen."
                    ),
                    type: "error"
                });
            }
        }
    },
    methods: mapActions("message", ["showMessage"])
};
</script>
