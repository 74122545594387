<template>
    <table :class="antlerClass()">
        <slot />
    </table>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppOverviewList",

    props: {
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("overview-list", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.overview-list
    position: relative

    width: 100%

.overview-list--scroll
    overflow-x: auto
    max-width: 100%
    max-height: 100%

    white-space: nowrap

    .overview-list__column
        white-space: nowrap

.overview-list-head
    position: relative

    @include font-size(13px)
    font-weight: $strong-weight
    text-align: left

    overflow: hidden

.overview-list--sticky
    .overview-list-head
        border-bottom: none

        .overview-list__column
            position: sticky
            top: 0
            z-index: $zindex-form

            &::after
                position: absolute
                bottom: 0
                left: 0
                z-index: 2

                width: 100%

                border-bottom:
                    width: 5px
                    style: solid
                    color: $color-blue-lighter

                content: ""

        .overview-list__column--avatar,
        .overview-list__column--name
            z-index: 3

        & + .overview-list-body
            border-top: 0

    .overview-list__column--avatar
        position: sticky
        left: 0

        background-color: #fff

    .overview-list__column--name
        position: sticky
        left: 52px

        background-color: #fff

    .overview-list-body
        .overview-list__column--avatar,
        .overview-list__column--name
            z-index: $zindex-content

.overview-list-body
    margin-bottom: $spacing-regular

    .overview-list-head + &,
    &--bordered
        border-top:
            width: 5px
            style: solid
            color: $color-blue-lighter

    .overview-list__row
        &:nth-child(odd)
            background-color: tint($color-blue-lightest, 40)

            .overview-list__column--name,
            .overview-list__column--avatar
                background-color: tint($color-blue-lightest, 40)

.overview-list__row
    border-bottom:
        width: 1px
        style: solid
        color: $color-blue-lighter
    transition: $global-transition-fast

.overview-list__column
    position: relative

    padding:
        top: $spacing-small
        right: $spacing-small
        bottom: $spacing-small

    transition: $global-transition-fast

    &:last-child
        padding-right: 0

.overview-list-pagination
    margin-bottom: $spacing-regular

/* List Types */
/*---------------------------------------------------------------------------*/

.overview-list--hover
    .overview-list-body
        .overview-list__row
            &:hover
                background-color: $color-blue-lightest

                .overview-list__column--name,
                .overview-list__column--avatar
                    background-color: $color-blue-lightest

/* Row Types */
/*---------------------------------------------------------------------------*/

.overview-list__row--hover
    &:hover
        background-color: $color-blue-lightest

.overview-list__row--link
    cursor: pointer

.overview-list__row--clickable
    cursor: pointer

/* Column Types */
/*---------------------------------------------------------------------------*/

.overview-list__column--fill
    width: 100%

.overview-list__column--avatar
    width: 40px
    min-width: 40px

.overview-list__column--name
    width: 200px
    max-width: 200px

.overview-list__column--truncate
    max-width: 1px
    @extend %truncate

.overview-list__column--grade
    width: 60px
    min-width: 60px
    padding-right: 0

    text-align: center

/* Column Sizes */
/*---------------------------------------------------------------------------*/

.overview-list__column--small
    width: 100px
    min-width: 100px

/* Header Types */
/*---------------------------------------------------------------------------*/

.overview-list-head--rotated
    .overview-list__column
        position: relative

        height: 190px
        padding: 0

        text-align: left

        white-space: normal
        vertical-align: bottom

    .overview-list-head__spacer
        position: absolute
        top: 0
        right: -50px
        z-index: 1

        display: block
        width: 100%
        height: 100%
        background-color: #fff

        transform: skewX(-25deg)

    .overview-list__column--fill
        .overview-list-head__spacer
            right: auto
            left: 0

            width: 100%
            transform: skewX(0)
            clip-path: polygon(90px 0, 100% 0, 100% 100%, 0% 100%)

    .overview-list-head__name
        position: relative

        width: 56px
        height: 100%

        font-weight: normal

    .overview-list-head__name-text
        position: absolute
        bottom: 0
        left: 0

        display: inline-flex
        align-items: center
        height: 56px // same as width
        width: 260px
        padding:
            top: 0
            right: 90px
            bottom: 0
            left: 24px

        line-height: 1.2

        transform-origin: bottom left
        transform: translateX(62px) rotate(-65deg)

        &::before
            position: absolute
            top: 0
            left: -24px
            z-index: 0

            display: block
            height: 100%
            width: 48px

            content: ""
            clip-path: polygon(0 0, 100% 0, 100% 100%, 45% 100%)

/* Levels */
/*---------------------------------------------------------------------------*/

.overview-list--group
    table-layout: fixed

    .overview-list-head
        .overview-list__column--grade
            .overview-list-head__name-text
                background-color: #fff

                &::before
                    background-color: #fff

        .overview-list__column--grade:nth-child(odd)
            .overview-list-head__name-text
                background-color: $color-blue-lightest

                &::before
                    background-color: $color-blue-lightest

    .overview-list-body
        .overview-list__row
            background-color: transparent

            .overview-list__column--name
                background-color: #fff

            .overview-list__column--avatar
                background-color: transparent

        .overview-list__column--grade:nth-child(odd)
            background-color: $color-blue-lightest

    &.overview-list--sticky
        .overview-list__column:first-child::after
            width: 105%

        .overview-list__column--name
            left: 0

/* Reports */
/*---------------------------------------------------------------------------*/

.overview-list--reports
    @include font-size(18px)

    .overview-list__row
        border-bottom:
            width: 1px
            style: dashed
            color: $color-blue-light

        &:first-child
            border-top:
                width: 1px
                style: dashed
                color: $color-blue-light
</style>
