var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TheLayout',{staticClass:"layout--vertical"},[_c('AppHeader',{staticClass:"layout-header",attrs:{"variant":"centered"}},[_c('AppTitle',[_c('AppLink',{staticClass:"u-margin-right-small",attrs:{"to":{ name: 'schedule_weekly_overview' }}},[_c('AppIcon',{staticClass:"icon--tiny u-margin-right-small u-rotate-90",attrs:{"name":"arrow"}}),_c('translate',[_vm._v("Weekplanning")])],1)],1)],1),_c('AppLayoutInner',{attrs:{"variant":"background"}},[_c('AppForm',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('AppPageHeader',{attrs:{"header-variant":"centered"}},[(_vm.scheduleTemplate.group.name)?_c('AppTitle',{directives:[{name:"translate",rawName:"v-translate",value:({
                        group: _vm.scheduleTemplate.group.name,
                        grades: _vm.scheduleTemplate.grades.join('/'),
                        weekday: _vm.weekdays[_vm.scheduleTemplate.weekday]
                    }),expression:"{\n                        group: scheduleTemplate.group.name,\n                        grades: scheduleTemplate.grades.join('/'),\n                        weekday: weekdays[scheduleTemplate.weekday]\n                    }"}],staticClass:"header__title"},[_vm._v(" %{group} - Groep %{grades} - %{weekday} ")]):_vm._e(),_c('div',{staticClass:"u-margin-left-auto"},[_c('AppButton',{staticClass:"page-header__button",attrs:{"variant":"primary medium","disabled":!_vm.onLine,"icon":"check-small","loading":_vm.loading > 0,"title":_vm.$gettext('Opslaan'),"type":"submit"}},[_c('translate',[_vm._v("Opslaan")])],1)],1)],1),_c('AppScheduleFormItemGroup',{on:{"addScheduleRow":_vm.addScheduleRow}},[_c('Draggable',{attrs:{"disabled":_vm.loading > 0,"handle":".button--draggable"},on:{"change":_vm.changeSchedule},model:{value:(_vm.formValues.scheduleTemplateRows),callback:function ($$v) {_vm.$set(_vm.formValues, "scheduleTemplateRows", $$v)},expression:"formValues.scheduleTemplateRows"}},_vm._l((_vm.formValues.scheduleTemplateRows),function(row){return _c('AppScheduleFormItem',{key:row.index,attrs:{"context":"weekly","delete-item":row.delete,"disabled":_vm.loading > 0,"enable-end-time":!_vm.lastNotDeleted ||
                                row.index === _vm.lastNotDeleted.index,"end-time":row.endTime,"form-index":row.index,"form-errors":_vm.formErrors.scheduleTemplateRows.forms,"grades":_vm.scheduleTemplate.grades,"start-time":row.startTime,"subject-description":row.subjectDescription,"notes":row.notes,"subject-id":row.subjectId,"is-bundle":row.isBundle,"bundle-rows":row.bundleRows},on:{"update:endTime":[function($event){return _vm.$set(row, "endTime", $event)},function($event){row.endTime = $event;
                            _vm.updateBundleRows();}],"update:end-time":function($event){return _vm.$set(row, "endTime", $event)},"update:subjectDescription":function($event){return _vm.$set(row, "subjectDescription", $event)},"update:subject-description":function($event){return _vm.$set(row, "subjectDescription", $event)},"update:notes":function($event){return _vm.$set(row, "notes", $event)},"update:subjectId":[function($event){return _vm.$set(row, "subjectId", $event)},function($event){row.subjectId = $event;
                            _vm.updateBundleRows();}],"update:subject-id":function($event){return _vm.$set(row, "subjectId", $event)},"update:deleteItem":function($event){row.delete = $event;
                            _vm.updateEndTimes();
                            _vm.updateBundleRows();},"update:startTime":function($event){row.startTime = $event;
                            _vm.updateEndTimes();
                            _vm.updateBundleRows();},"update:isBundle":function($event){row.isBundle = $event;
                            _vm.updateBundleRows();},"addScheduleBundleRow":function($event){return _vm.addScheduleBundleRow(row)}}})}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }