<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppPage",
    props: {
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("page", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.page
    flex: 1
    width: 100%
    padding:
        top: $spacing-regular
        right: $spacing-regular
        bottom: $spacing-regular

    +mq-lte-tablet
        padding-left: $spacing-regular

    .container &
        max-width: 100%
        padding:
            right: 0
            left: 0

    &.layout--max-height
        padding-bottom: 0

.page--no-padding-top
    padding-top: 0
</style>
