<template>
    <AppHeader :class="antlerClass()" :variant="headerVariant">
        <slot />
    </AppHeader>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppPageHeader",
    components: { AppHeader },
    props: {
        headerVariant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("page-header", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.page-header
    width: 100%

    +mq-tablet-portrait
        display: flex

.page-header-content
    +mq-tablet-portrait
        display: flex
        align-items: center
        justify-content: flex-end
        flex: 1
        margin-left: auto

.page-header__button
    +mq-tablet-portrait
        margin-left: $spacing-regular
</style>
