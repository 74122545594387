import { mapActions, mapGetters } from "vuex";
import { cloneDeep } from "lodash";

export default scheduleTemplateKey => {
    return {
        data() {
            return {
                loading: 0
            };
        },
        computed: {
            ...mapGetters("online", ["onLine"]),
            highestItemIndex() {
                let highestIndex = 0;
                for (
                    let i = 0;
                    i < this.formValues[scheduleTemplateKey].length;
                    i++
                ) {
                    const row = this.formValues[scheduleTemplateKey][i];
                    const lastBundleRow =
                        row.bundleRows[row.bundleRows.length - 1];
                    if (lastBundleRow.index > highestIndex) {
                        highestIndex = lastBundleRow.index;
                    }
                }
                return highestIndex;
            },
            lastNotDeleted() {
                return this.formValues[scheduleTemplateKey]
                    .slice()
                    .reverse()
                    .find(x => !x.delete);
            }
        },
        methods: {
            ...mapActions("message", ["showMessage"]),
            formatTime(val) {
                if (!val) return null;
                return this.$moment(val, "HHmm").format("HH:mm");
            },
            updateEndTimes() {
                this.formValues[scheduleTemplateKey]
                    .filter(x => !x.delete)
                    .forEach((row, index, rows) => {
                        if (rows.length - 1 !== index) {
                            row.endTime = rows[index + 1].startTime;
                        }
                    });
            },
            updateBundleRows() {
                this.formValues[scheduleTemplateKey]
                    .filter(x => x.isBundle)
                    .forEach(row => {
                        const syncDelete =
                            row.delete || row.bundleRows.every(x => x.delete);
                        row.bundleRows.forEach(bundleRow => {
                            bundleRow.startTime = row.startTime;
                            bundleRow.endTime = row.endTime;
                            bundleRow.subjectBundleId = row.subjectId;
                            if (syncDelete) {
                                bundleRow.delete = row.delete;
                            }
                        });
                    });
            },
            changeSchedule({ moved }) {
                if (moved) {
                    if (
                        moved.newIndex ===
                        this.formValues[scheduleTemplateKey].length - 1
                    ) {
                        const row = this.formValues[scheduleTemplateKey][
                            moved.newIndex - 1
                        ];
                        moved.element.startTime = row.endTime;
                        moved.element.endTime = row.endTime;
                    } else {
                        const row = this.formValues[scheduleTemplateKey][
                            moved.newIndex + 1
                        ];
                        moved.element.startTime = row.startTime;
                        moved.element.endTime = row.startTime;
                    }
                }
                this.updateEndTimes();
            },
            addScheduleRow() {
                const rows = this.formValues[scheduleTemplateKey];
                const row = {
                    ...this.emptyScheduleRow,
                    startTime: rows.length ? rows[rows.length - 1].endTime : "",
                    index: this.highestItemIndex + 1
                };
                row.bundleRows = [cloneDeep(row)];
                rows.push(row);
            },
            addScheduleBundleRow(scheduleRow) {
                const bundleRows = scheduleRow.bundleRows;
                bundleRows.push({
                    ...this.emptyScheduleRow,
                    startTime: scheduleRow.startTime,
                    endTime: scheduleRow.endTime,
                    subjectBundleId: scheduleRow.subjectId,
                    index: this.highestItemIndex + 1
                });
            },
            getRawId(id) {
                return id ? atob(id).split(":")[1] : "";
            }
        }
    };
};
