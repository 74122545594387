<template>
    <TheRoot as="main" class="layout--vertical">
        <AppContainer class="container d-flex--fill">
            <AppPage>
                <slot />
            </AppPage>
        </AppContainer>
    </TheRoot>
</template>
<script>
import AppContainer from "@/components/AppContainer.vue";
import AppPage from "@/components/AppPage.vue";
import TheRoot from "@/components/TheRoot.vue";

export default {
    components: { TheRoot, AppPage, AppContainer }
};
</script>
