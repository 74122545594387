<template>
    <TheLayout class="layout--vertical">
        <AppHeader class="layout-header" variant="centered">
            <AppTitle>
                <AppLink
                    :to="{ name: 'schedule_weekly_overview' }"
                    class="u-margin-right-small"
                >
                    <AppIcon
                        name="arrow"
                        class="icon--tiny u-margin-right-small u-rotate-90"
                    />
                    <translate>Weekplanning</translate>
                </AppLink>
            </AppTitle>
        </AppHeader>

        <AppLayoutInner variant="background">
            <AppForm ref="form" lazy-validation @submit.prevent="formSubmit">
                <AppPageHeader header-variant="centered">
                    <AppTitle
                        v-if="scheduleTemplate.group.name"
                        v-translate="{
                            group: scheduleTemplate.group.name,
                            grades: scheduleTemplate.grades.join('/'),
                            weekday: weekdays[scheduleTemplate.weekday]
                        }"
                        class="header__title"
                    >
                        %{group} - Groep %{grades} - %{weekday}
                    </AppTitle>

                    <div class="u-margin-left-auto">
                        <AppButton
                            variant="primary medium"
                            class="page-header__button"
                            :disabled="!onLine"
                            icon="check-small"
                            :loading="loading > 0"
                            :title="$gettext('Opslaan')"
                            type="submit"
                        >
                            <translate>Opslaan</translate>
                        </AppButton>
                    </div>
                </AppPageHeader>

                <AppScheduleFormItemGroup @addScheduleRow="addScheduleRow">
                    <Draggable
                        v-model="formValues.scheduleTemplateRows"
                        :disabled="loading > 0"
                        handle=".button--draggable"
                        @change="changeSchedule"
                    >
                        <AppScheduleFormItem
                            v-for="row in formValues.scheduleTemplateRows"
                            :key="row.index"
                            context="weekly"
                            :delete-item="row.delete"
                            :disabled="loading > 0"
                            :enable-end-time="
                                !lastNotDeleted ||
                                    row.index === lastNotDeleted.index
                            "
                            :end-time.sync="row.endTime"
                            :form-index="row.index"
                            :form-errors="formErrors.scheduleTemplateRows.forms"
                            :grades="scheduleTemplate.grades"
                            :start-time="row.startTime"
                            :subject-description.sync="row.subjectDescription"
                            :notes.sync="row.notes"
                            :subject-id.sync="row.subjectId"
                            :is-bundle="row.isBundle"
                            :bundle-rows="row.bundleRows"
                            @update:deleteItem="
                                row.delete = $event;
                                updateEndTimes();
                                updateBundleRows();
                            "
                            @update:startTime="
                                row.startTime = $event;
                                updateEndTimes();
                                updateBundleRows();
                            "
                            @update:endTime="
                                row.endTime = $event;
                                updateBundleRows();
                            "
                            @update:isBundle="
                                row.isBundle = $event;
                                updateBundleRows();
                            "
                            @update:subjectId="
                                row.subjectId = $event;
                                updateBundleRows();
                            "
                            @addScheduleBundleRow="addScheduleBundleRow(row)"
                        />
                    </Draggable>
                </AppScheduleFormItemGroup>
            </AppForm>
        </AppLayoutInner>
    </TheLayout>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppForm from "@/components/AppForm";
import AppHeader from "@/components/AppHeader.vue";
import AppIcon from "@/components/AppIcon";
import AppLayoutInner from "@/components/AppLayoutInner.vue";
import AppLink from "@/components/AppLink.vue";
import AppPageHeader from "@/components/AppPageHeader.vue";
import AppScheduleFormItem from "@/components/AppScheduleFormItem.vue";
import AppScheduleFormItemGroup from "@/components/AppScheduleFormItemGroup.vue";
import AppTitle from "@/components/AppTitle.vue";
import Draggable from "vuedraggable";
import FormSave from "@/mixins/FormSave";
import Schedules from "@/mixins/Schedules";
import TheLayout from "@/components/TheLayoutDefault";
import { cloneDeep } from "lodash";
import { getScheduleRows } from "@/utils/getScheduleRows";
import gql from "graphql-tag";

export default {
    name: "WeeklyDetail",
    components: {
        AppScheduleFormItem,
        AppScheduleFormItemGroup,
        AppPageHeader,
        AppLayoutInner,
        AppLink,
        AppTitle,
        AppHeader,
        AppButton,
        AppForm,
        AppIcon,
        Draggable,
        TheLayout
    },
    mixins: [FormSave, Schedules("scheduleTemplateRows")],
    props: {
        scheduleTemplateId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            emptyFormErrors: {
                scheduleTemplateRows: {
                    nonFormErrors: [],
                    forms: []
                }
            },
            emptyScheduleRow: {
                index: null,
                id: "",
                startTime: null,
                endTime: null,
                subjectId: "",
                subjectDescription: "",
                notes: "",
                isBundle: false,
                bundleRows: [],
                delete: false
            },
            formValues: {
                scheduleTemplateRows: []
            },
            scheduleTemplate: {
                group: {},
                grades: {},
                scheduleTemplateRows: []
            }
        };
    },
    computed: {
        numericId() {
            return atob(this.scheduleTemplateId).split(":")[1];
        },
        weekdays() {
            return this.$moment.weekdays(true).reduce((acc, value, index) => {
                acc[`A_${index}`] = value;
                return acc;
            }, {});
        }
    },
    apollo: {
        scheduleTemplate: {
            query: gql`
                query scheduleTemplateWeeklyDetail($id: ID!) {
                    scheduleTemplate(id: $id) {
                        id
                        grade {
                            id
                            grade
                        }
                        group {
                            id
                            name
                            grades
                        }
                        scheduleTemplateRows {
                            edges {
                                node {
                                    id
                                    startTime
                                    endTime
                                    subjectBundle {
                                        id
                                        name
                                    }
                                    subject {
                                        id
                                        name
                                    }
                                    subjectDescription
                                    notes
                                }
                            }
                        }
                        weekday
                        modified
                    }
                }
            `,
            variables() {
                return {
                    id: this.scheduleTemplateId
                };
            },
            update(data) {
                if (data.scheduleTemplate) {
                    const schedule = { ...data.scheduleTemplate };
                    schedule.grades = schedule.grade
                        ? [schedule.grade.grade]
                        : schedule.group.grades;

                    schedule.scheduleTemplateRows = getScheduleRows(
                        schedule.scheduleTemplateRows.edges,
                        node => ({
                            ...cloneDeep(this.emptyScheduleRow),
                            id: node.id,
                            startTime: this.formatTime(node.startTime),
                            endTime: this.formatTime(node.endTime),
                            notes: node.notes,
                            subjectId: node.subject ? node.subject.id : null,
                            subjectDescription: node.subjectDescription
                        }),
                        node => ({
                            ...cloneDeep(this.emptyScheduleRow),
                            id: node.id,
                            startTime: this.formatTime(node.startTime),
                            endTime: this.formatTime(node.endTime),
                            subjectId: node.subjectBundle.id
                        })
                    );
                    this.$nextTick(() => {
                        this.setFormValues(schedule);
                    });
                    return schedule;
                } else {
                    return this.scheduleTemplate;
                }
            }
        }
    },
    methods: {
        getMutation() {
            const initialForms = this.scheduleTemplate.scheduleTemplateRows.reduce(
                (acc, x) => {
                    return acc + x.bundleRows.length;
                },
                0
            );
            const scheduleTemplateRows = this.formValues.scheduleTemplateRows
                .reduce((acc, x) => {
                    if (x.isBundle) {
                        return acc.concat(x.bundleRows);
                    } else {
                        // add remaining bundle rows to delete, this will be
                        // needed when a schedule item switches from bundle to non bundle
                        x.bundleRows.forEach((val, i) => {
                            if (i !== 0) {
                                acc.push({
                                    ...val,
                                    delete: true
                                });
                            }
                        });
                        acc.push(x);
                        return acc;
                    }
                }, [])
                .sort((a, b) => {
                    return a.index - b.index;
                });

            const input = {
                id: this.numericId,
                scheduleTemplateRowsInitialForms: initialForms,
                scheduleTemplateRowsTotalForms: scheduleTemplateRows.length,
                scheduleTemplateRows: scheduleTemplateRows.map(val => {
                    return {
                        id: val.id ? atob(val.id).split(":")[1] : "",
                        startTime: val.startTime
                            ? this.formatTime(val.startTime)
                            : null,
                        endTime: val.endTime
                            ? this.formatTime(val.endTime)
                            : null,
                        subject: val.subjectId
                            ? atob(val.subjectId).split(":")[1]
                            : "",
                        subjectBundle: val.subjectBundleId
                            ? this.getRawId(val.subjectBundleId)
                            : "",
                        subjectDescription: val.subjectDescription,
                        notes: val.notes,
                        delete: val.delete
                    };
                })
            };

            return {
                mutation: gql`
                    mutation scheduleTemplateWeeklyDetail(
                        $input: ScheduleTemplateMutationInput!
                    ) {
                        scheduleTemplate(input: $input) {
                            scheduleTemplate {
                                id
                                scheduleTemplateRows {
                                    edges {
                                        node {
                                            id
                                            startTime
                                            endTime
                                            subjectBundle {
                                                id
                                                name
                                            }
                                            subject {
                                                id
                                                name
                                            }
                                            subjectDescription
                                            notes
                                        }
                                    }
                                }
                                modified
                            }
                            errors {
                                ... on ErrorType {
                                    field
                                    messages
                                }
                                ... on ErrorTypeFormset {
                                    field
                                    formIndex
                                    errors {
                                        field
                                        messages
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                }
            };
        },
        formSuccess() {
            this.showMessage({
                message: this.$gettext(
                    "Wijzigingen zijn succesvol opgeslagen."
                ),
                type: "success"
            });
        }
    }
};
</script>
