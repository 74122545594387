import { cloneDeep } from "lodash";

/**
 * @param {{node: object}[]} schedules
 * @param {(node: object) => object} createRow
 * @param {(node: object) => object} createBundleRow
 * @param {boolean} [addBundleRows=true] - Whether to add bundleRows to all top-level rows
 * @returns {object[]} rows
 */
export function getScheduleRows(
    schedules,
    createRow,
    createBundleRow,
    addBundleRows = true
) {
    /** @type {Map<string, object[]>} */
    const bundleRows = new Map();
    return schedules.reduce((acc, { node }, i) => {
        if (node.subjectBundle) {
            const bundleKey = `${node.subjectBundle.id}-${node.startTime}-${node.endTime}`;
            if (!bundleRows.has(bundleKey)) {
                const bundle = {
                    ...createBundleRow(node),
                    index: i,
                    isBundle: true
                };
                acc.push(bundle);
                bundleRows.set(bundleKey, bundle.bundleRows);
            }
            bundleRows.get(bundleKey).push({
                ...createRow(node),
                index: i,
                subjectBundleId: node.subjectBundle.id
            });
            return acc;
        }

        const row = {
            ...createRow(node),
            index: i
        };
        if (addBundleRows) {
            row.bundleRows = [cloneDeep(row)];
        }
        acc.push(row);
        return acc;
    }, []);
}
